@charset "utf-8";

@import "../../node_modules/bulma";

body {
    background-color: $light;
    height: 100vh;
    transition: all 0.4s ease;
}

// Some styling

body .subtitle,
body .title {
    color: $dark;
}

body.dark {
    background-color: $dark;
    color: $light;
}

body.dark .subtitle,
body.dark .title {
    color: $light;
}

.section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

button{
    cursor: pointer;
    border: none;
    color: $light;
    padding: .8rem;
    background: $dark;
    border-radius: 6px;
}

body.dark button {
    background: $light;
    color: $dark;
}
